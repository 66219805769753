import { CognitoUserPool, AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { ServerConfig } from '../../connectors/Config';
import axios from 'axios';
import GzipHelper from './GzipHelper';

const asyncAuthenticateUser = (cognitoUser, cognitoAuthenticationDetails) => {
    
    return new Promise((resolve, reject) => {
        cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
            onSuccess: result => {
                resolve({status: 'ok', message: result})
            },
            
            onFailure: err => {console.log(err); reject(err)},
            newPasswordRequired: err => resolve({status: 'new_password', message: 'new_password_required'}),
            mfaRquired: (codeDeliveryDetails) => {
                resolve({status: 'mfa_required'});
            },
            totpRequired: (mfaType) => {
               resolve({status: 'mfa_required', user: cognitoUser});
            }
        })
    });
}

const sendMFACode = async (validationCode, user) => await new Promise((res, rej) => {
    user.sendMFACode(validationCode,
        {
            onSuccess: (result) => res(console.log(result)),
            onFailure: () => rej(console.log('errore codice'))
        },
    'SOFTWARE_TOKEN_MFA');
})

const asyncChangePassword = (cognitoUser, newPassword) => {
    return new Promise((resolve, reject) => {
        cognitoUser.completeNewPasswordChallenge(
            newPassword,
            null,
            {
                onSuccess: () => resolve({status: 'ok', message: 'Password cambiata con successo'}),
                onFailure: (err) => reject({status: 'error', message: err})
            }
        )
    });
}

const asyncConfirmUser = (cognitoUser, verificationCode) => {
    return new Promise((res, rej) => {
        cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
            if(err) rej(err);
            res(result);
        });
    })
}
class AuthHelper {
    constructor(avoidPrivate = false){
        if(avoidPrivate){
            this.userPool = new CognitoUserPool(ServerConfig.AWS.Cognito);
            this.oldPassword = "";
            if(new URLSearchParams(window.location.href).get('invalidate'))localStorage.clear();
            
        }else{
            throw new Error("This class is a Singleton. Retrieve it through the getIstance static method.");
        }
    }
    static getInstance = () => {
        if(!this.istance){
            this.istance = new AuthHelper(true);
        }
        return this.istance;
    }
    static getUserFromIdToken = async (idToken = null) => {
        try{
            idToken = idToken ? idToken : localStorage.getItem('cruddy-apiKey');
            const url = `https://cognito-idp.${ServerConfig.AWS.Cognito.Region}.amazonaws.com/`
            const res = await axios.post(url, {
                AccessToken: idToken
            }, {
                headers: {
                    "X-Amz-Target": "AWSCognitoIdentityProviderService.GetUser",
                    "Content-Type": "application/x-amz-json-1.1",
                }
            });
             return res.data;
        }catch(ex){
            if(ex.response.status === 400){
                localStorage.clear();
                //window.location.href=`http://cruddy-sso.s3-website-eu-west-1.amazonaws.com/?invalidate=true&return-url=${window.location.href}`
                
            }
        }
        
    }

    getRoles = () => {
        return JSON.parse(JSON.parse(localStorage.getItem('cruddy-user')).idToken.payload['custom:roles']);
    }
    login = async(credentials) => {
        let cognitoUser;
        try{
            const authenticationDetails = new AuthenticationDetails(credentials);
            const userData = {
                Username: credentials.Username,
                Pool: this.userPool
            }
            cognitoUser = new CognitoUser(userData);
            const response = await asyncAuthenticateUser(cognitoUser, authenticationDetails);
            console.log(response);
            if(response.message?.accessToken){
                const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/get-qr-code?accessToken=${response.message.accessToken.jwtToken}`)
                return {...response, img: await res.text(), jwt: response.message.accessToken.jwtToken, cachedUser: cognitoUser};
            }
            this.user = cognitoUser;
            this.oldPassword = credentials.Password;

            return {...response, cachedUser: cognitoUser};
           
        }catch(ex){
            return {status: 'error', response: ex, cachedUser: cognitoUser};
        }
    }

    static confirmUser = async (user, confirmationCode) => {
        const res = await asyncConfirmUser(user, confirmationCode);
        return res;
    }
    static validateMFA = async (userCode, accessToken) => {
        const res = await fetch(`https://45bm2i72wc.execute-api.eu-west-1.amazonaws.com/dev/api/v1/validate-qr-code?userCode=${userCode}&accessToken=${accessToken}`);
        const json = await res.json();
        const gzipDeflated = await GzipHelper.unzip(json);
        return JSON.parse(gzipDeflated);
    }

    static sendMFACode = async(code, user) => {
        const res = await sendMFACode(code, user);
        return res;
    }
    logout = () => {
        localStorage.clear();
        return true;
    }
    changePassword = async(user, newPassword) => {
        try{
            const res = await asyncChangePassword(user, newPassword);
            return {status: 'success', response: res};
        }catch(ex){
            console.log(ex);
            return {status: 'error', response: ex};
        }
    }
}

export { AuthHelper };