import { Button, Drawer, Stack } from "@mui/material";
import { createContext, useState} from "react";
import { Colors, Gaps, Variables } from "../../misc/variables";
import { Header } from "../components/Login/Header";

// ICONS IMPORT
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { AuthHelper } from "../controllers/AuthHelper";

// import { ChildRouter } from "../../router/DashboardRouter";


// -----------

// const getDynamicIcon = (name) => {
//   return import(`@mui/icons-material/${name}`);
// }

export const DashboardContext = createContext();

export const DashboardDrawerProvider = ({ children, routerContext }) => {
  const [defaultOpen, setDefaultOpen] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);

  const pushMobileOpen = (param) => { setDefaultOpen(param) };
  
  const authHelper = AuthHelper.getInstance();
  
  let navigate = null;
  const addRouter = (param) => {navigate = param};


  
  return (
    <DashboardContext.Provider value={{pushMobileOpen, defaultOpen, addRouter}}>
      {children}
      <Drawer
        sx={{
          width: Variables.drawerWidth,
          flexShrink: 0,
          backgroundColor: `${Colors.primaryColor} !important`,
          '& .MuiDrawer-paper': {
            width: Variables.drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        PaperProps={{
          sx: {backgroundColor: `${Colors.secondaryColor} !important`, border: 0}
        }}
        variant="persistent"
        anchor="left"
        open={defaultOpen}>
          <Header small helloThere/>
          {/* <Stack sx={{flex: 1, marginTop: Gaps.defaultGap}} spacing={2}>
              {ChildRouter.map(route => (
                <Button sx={{color: '#fff'}} onClick={() => routerContext.navigate(`/dashboard${route.path}`)}>{route.title}</Button>
              ))}
          </Stack> */}
          <div style={{paddingBottom: Gaps.defaultGap, textAlign: 'center'}}>
            <Button 
              variant="contained"
              sx={{borderRadius: 0, width: Variables.drawerWidth / 1.3}}
              onClick={() => {authHelper.logout(); routerContext.navigate("/")}}
              fullWidth={false}>
                <ExitToAppIcon style={{marginRight: 6}} />
                Esci
            </Button>
          </div>
          
      </Drawer>
    </DashboardContext.Provider>
  )
}