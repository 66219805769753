const ServerConfig = {
    AWS: {
        secret: "u4DrCcw5snMXXFll3PfjMBYGo+DZh2TdqELzqjlQ",
        accessKey: "AKIA2VBRBN7HKLZBKDNY",
        Cognito: {
            Region: "eu-west-1",
            UserPoolId: "eu-west-1_vaZzKUj4G",
            ClientId: "3ins7cv4qnsfpjep2akfdnd20v",
        }
    }
}

export { ServerConfig };