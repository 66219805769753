import './App.css';
import { Router } from './router/Router';
import { RouterProvider} from "react-router-dom";
import { Footer } from './views/components/Footer';
import { createTheme, ThemeProvider } from '@mui/material';
import { Colors } from './misc/variables';
import { SnackBarProvider } from './views/providers/SnackBarProvider';
import { DashboardDrawerProvider } from './views/providers/DashboardDrawerProvider';
import { useRef } from 'react';

const theme = createTheme({
  palette: {
      mode: 'dark',
      text: {
        primary: 'rgba(255,255,255,0.87)',
        secondary: 'rgba(220,220,220,0.54)',
        disabled: 'rgba(142,130,130,0.38)',
        hint: 'rgba(70,66,66,0.38)',
      },
      primary: {
        main: Colors.primaryColor
      },
      secondary: {
        main: Colors.secondaryColor
      }
  }
});

function App() {
  const routerRef = useRef(null);
  return (
    <ThemeProvider theme={theme}>
      <DashboardDrawerProvider routerContext={Router}>
        <SnackBarProvider>
          <div className="App">
              <div className="page">
                <RouterProvider router={Router} ref={routerRef} />
              </div>
          </div>
        </SnackBarProvider>
      </DashboardDrawerProvider>
    </ThemeProvider>
    
  );
}

export default App;
