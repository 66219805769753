import { Box, Typography } from "@mui/material"
import React from "react";
import Logo from '../../../assets/images/logo.svg';
import { Colors } from "../../../misc/colors";

const Header = (props) => {
    const user = JSON.parse(localStorage.getItem('cruddy-user'));
    
    return (<Box sx={{height: '20vh', display: 'flex', flexDirection: 'column', backgroundColor: Colors.primaryColor, width: '100%', alignItems: 'center'}}>
        <img src={Logo} className={!props.small ? 'logo' : 'logo-small'} alt="logo"/>
        {props.helloThere && <Typography variant="h6">Bentornato <strong>{user && user.idToken.payload.name}</strong></Typography>}
    </Box>)
}

export { Header };